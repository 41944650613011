// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const ImagoDarkIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.38 147.2">
        <g id="pink_lines">
            <path
                className="cls-3"
                fill="#e01075"
                d="m124.59,110.57s0-.01.01-.02c0,0,0,.01-.01.02Z"
            />
        </g>
        <g id="black_lines">
            <path
                className="cls-2"
                fill="#fff"
                d="m79.31,17.81c-32.75,0-59.3,26.55-59.3,59.3s26.55,59.3,59.3,59.3,59.3-26.55,59.3-59.3-26.55-59.3-59.3-59.3Zm0,95.82c-20.17,0-36.52-16.35-36.52-36.52s16.35-36.52,36.52-36.52,36.52,16.35,36.52,36.52-16.35,36.52-36.52,36.52Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m81.78,113.53s0,0,0,0c-.35.02-.69.04-1.04.06-.48.02-.95.04-1.44.04-.52,0-1.05-.01-1.56-.04-.49-.02-.97-.06-1.45-.09h0l-12.73,20.78c1.59.44,3.2.81,4.84,1.11l13.39-21.85Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m126.4,113.13l-13.45-21.78c-.33.78-.69,1.53-1.07,2.28-.06.12-.13.25-.19.37-.4.76-.81,1.5-1.26,2.22l12.8,20.72c1.11-1.23,2.16-2.5,3.17-3.82Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m135.01,56.7h-25.4c1,1.49,1.9,3.07,2.67,4.71h24.21c-.43-1.59-.92-3.17-1.48-4.71Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m76.85,40.68h0c.81-.05,1.63-.09,2.46-.09.53,0,1.05.01,1.57.04.49.02.98.06,1.46.1l12.73-20.78c-1.59-.44-3.2-.81-4.84-1.11l-13.39,21.85Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m32.22,41.08l13.45,21.78s0,0,0,0c.33-.78.69-1.53,1.07-2.28.06-.12.13-.25.19-.37.4-.76.81-1.5,1.26-2.22l-12.8-20.72c-1.11,1.23-2.16,2.5-3.17,3.82Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m23.61,97.51h25.4c-.46-.68-.88-1.38-1.29-2.08-.08-.14-.16-.27-.23-.41-.41-.72-.8-1.46-1.16-2.22h-24.2c.44,1.59.92,3.17,1.49,4.71Z"
            />
            <path
                className="cls-1"
                fill="#fa3592"
                d="m79.31,40.58c-20.17,0-36.52,16.35-36.52,36.52s16.35,36.52,36.52,36.52,36.52-16.35,36.52-36.52-16.35-36.52-36.52-36.52Zm10.52,53.62l-21,.02-10.52-17.1,10.48-17.12,21-.02,10.52,17.1-10.48,17.12Z"
            />
            <path
                className="cls-2"
                fill="#fff"
                d="m112.95,91.35l-18.49-29.94h17.84c-.78-1.64-1.68-3.21-2.69-4.71h-37.06s9.8-15.99,9.8-15.99c-1-.08-2.01-.14-3.03-.14-.83,0-1.65.04-2.46.09l-19.6,31.98-9.06-14.67c-.96,1.55-1.8,3.18-2.52,4.87l18.49,29.94h-17.84c.78,1.64,1.68,3.21,2.69,4.71h18.06s0,0,0,0h19.01s-9.8,15.99-9.8,15.99c1,.08,2,.13,3.02.13.83,0,1.66-.04,2.48-.09l19.59-31.96,9.05,14.66c.96-1.55,1.8-3.18,2.52-4.87Zm-23.98,1.45h-19.27l-9.67-15.66,9.64-15.73h19.26l9.68,15.67-9.63,15.72Z"
            />
        </g>
    </svg>
);
