// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const BlockSyncRGBSvg = function BlockSyncRGBSvg() {
    const xmlnsAttributes = {
        'xmlns:x': '&ns_extend',
        'xmlns:i': '&ns_ai',
        'xmlns:graph': '&ns_graphs',
    };
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            {...xmlnsAttributes}
            enableBackground="new 0 0 1000 1000"
        >
            <path
                fill="#F5B11A"
                className="st0"
                d="M52.7,499.9c0-70.5,0.4-141-0.4-211.4c0-25.1,12-43.1,33.4-55.1c54-30.4,108.7-60,163.1-90
            c60-33.4,119.6-66.7,179.6-100.1c18-9.7,36-19.9,55.1-27.7c14.2-6,30-4.1,43.5,3c33,17.2,65.2,35.6,97.8,53.2
            c24.4,13.1,49.1,26.2,73.5,39.4c38.6,21,77.2,42,116.2,63c31.1,16.9,62.2,33.7,93.7,49.9c19.5,10.1,34.5,23.2,38.6,45
            c0.7,3.7,1.1,7.9,1.1,11.6c0,142.5,0,284.5,0,427c0,22.1-9.4,39.7-28.9,51c-43.1,24.7-86.6,48.7-130.1,73.5c-42,24-84,48-126,71.6
            c-45.7,25.9-91.8,51.7-138,76.9c-15.7,8.6-33.4,8.6-49.5,0c-34.5-18-68.6-37.1-102.7-55.9c-27.4-15-54.4-30-81.3-45.4
            c-31.1-17.2-62.2-34.9-93.7-52.1c-27-15-54-29.2-80.6-44.2c-16.1-9.4-32.2-18.7-47.2-30C57.2,743.6,53,729.7,53,714.7
            C52.7,643.9,52.7,571.9,52.7,499.9"
            />
            <path
                fill="#FFFFFF"
                stroke="#FFFFFF"
                strokeWidth={1.8744}
                strokeMiterlimit={10}
                className="st1"
                d="M705,420.8v184.4c0,3.7-2.2,7.1-5.2,9l-170.6,93c-6.7,3.7-15.4-1.1-15.4-9V513.8c0-3.7,2.2-7.1,5.2-9
            l170.2-92.6C696.3,408.1,705,413,705,420.8z M486,513.8v184.4c0,7.9-8.2,12.7-15.4,9l-170.2-93c-3.4-1.9-5.2-5.2-5.2-9V420.8
            c0-7.9,8.2-12.7,15.4-9l170.2,92.6C484.2,506.3,486,510.1,486,513.8z M674.2,389.7l-169.1,92.2c-3,1.5-6.7,1.5-9.7,0l-169.1-92.2
            c-7.1-3.7-7.1-14.2,0-18.4l169.1-92.2c3-1.5,6.7-1.5,9.7,0l169.1,92.2C681.3,375.5,681.3,386,674.2,389.7z M495,249.1L272.7,370.2
            c-3.4,1.9-5.2,5.2-5.2,9v241c0,3.7,2.2,7.1,5.2,9L495,750.4c3,1.5,6.7,1.5,9.7,0l222.3-121.1c3.4-1.9,5.2-5.2,5.2-9v-241
            c0-3.7-2.2-7.1-5.2-9L504.8,249.1C501.8,247.3,498,247.3,495,249.1z"
            />
        </svg>
    );
};
