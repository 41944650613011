// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const ImagoRGBIcon = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 118.6 118.6"
        enableBackground="0 0 118.6 118.6"
        xmlSpace="preserve"
    >
        <g id="sphere">
            <path
                className="st0"
                fill="#221F1F"
                d="M59.3,0C26.6,0,0,26.5,0,59.3s26.5,59.3,59.3,59.3s59.3-26.5,59.3-59.3S92,0,59.3,0z M74.3,67.2
            c-0.9,1.6-1.8,3.2-2.8,4.8c-0.8,1.2-1.7,2.5-3.1,3.1c-0.1,0.3-0.4,0.6-0.8,0.8c-3,1.3-6.8,1-10,0.9c-3-0.1-6.8-0.1-9.5-1.6
            c-0.5-0.3-0.7-0.7-0.8-1.2c-1.3-1.1-2.4-2.8-3.3-3.9c-1.8-2.6-3.5-5.4-4.8-8.3c-0.4-1-0.9-2-0.9-3.1c0-1.8,1.4-3.4,2.5-4.6
            c0.7-0.8,1.5-1.5,2.2-2.3c0.6-0.8,1-1.7,1.5-2.6c1-1.7,2.8-3.7,2.7-5.7c0-1,0.8-1.6,1.7-1.7c2.7-0.3,5.8-0.5,8.4,0.5
            c0.4-0.5,1-0.8,1.7-0.6c2.5,0.5,4.9,0.9,7.4,1.2c1.4,0.1,2.1,0.6,2.9,1.7c1.7,2.1,3.2,4.3,4.6,6.6c1.2,2,3,3.8,3.9,6
            C79.2,60.6,75.9,64.2,74.3,67.2z"
            />
            <path
                className="st1"
                fill="#DF1175"
                d="M59.2,22.1c-20.4,0-36.9,16.5-36.9,36.9s16.5,36.9,36.9,36.9S96.1,79.4,96.1,59S79.6,22.1,59.2,22.1z
             M74.3,67.2c-0.9,1.6-1.8,3.2-2.8,4.8c-0.8,1.2-1.7,2.5-3.1,3.1c-0.1,0.3-0.4,0.6-0.8,0.8c-3,1.3-6.8,1-10,0.9
            c-3-0.1-6.8-0.1-9.5-1.6c-0.5-0.3-0.7-0.7-0.8-1.2c-1.3-1.1-2.4-2.8-3.3-3.9c-1.8-2.6-3.5-5.4-4.8-8.3c-0.4-1-0.9-2-0.9-3.1
            c0-1.8,1.4-3.4,2.5-4.6c0.7-0.8,1.5-1.5,2.2-2.3c0.6-0.8,1-1.7,1.5-2.6c1-1.7,2.8-3.7,2.7-5.7c0-1,0.8-1.6,1.7-1.7
            c2.7-0.3,5.8-0.5,8.4,0.5c0.4-0.5,1-0.8,1.7-0.6c2.5,0.5,4.9,0.9,7.4,1.2c1.4,0.1,2.1,0.6,2.9,1.7c1.7,2.1,3.2,4.3,4.6,6.6
            c1.2,2,3,3.8,3.9,6C79.2,60.6,75.9,64.2,74.3,67.2z"
            />
        </g>
        <g id="pink_x5F_lines">
            <path
                className="st1"
                fill="#DF1175"
                d="M12.8,22.5c0,0,1.1-1.3,1.5-1.8s1.4-1.6,1.4-1.6l12.2,20.3l-2.3,4.3L12.8,22.5z"
            />
            <path
                className="st1"
                fill="#DF1175"
                d="M70.6,1.1c0,0,1.4,0.3,1.9,0.4c0.6,0.1,2.3,0.6,2.3,0.6L62.5,22.3l-4.7-0.1L70.6,1.1z"
            />
            <path
                className="st1"
                fill="#DF1175"
                d="M90.5,39.4l2.2,4h23.8c0,0-0.4-1.6-0.6-2.1c-0.1-0.5-0.7-1.9-0.7-1.9H90.5z"
            />
            <path
                className="st1"
                fill="#DF1175"
                d="M92.4,75.1l-2.1,3.7l12.5,20.6c0,0,1.2-1.3,1.6-1.8c0.4-0.4,1.5-1.8,1.5-1.8L92.4,75.1z"
            />
            <path
                className="st1"
                fill="#DF1175"
                d="M55.9,95.8l4.8,0.1l-12.3,21.6c0,0-1.8-0.3-2.4-0.4c-0.6-0.1-2.2-0.6-2.2-0.6L55.9,95.8z"
            />
            <path
                className="st1"
                fill="#DF1175"
                d="M2.1,74.7c0,0,0.4,1.5,0.6,2.1s0.9,2.5,0.9,2.5h24.9l-2.6-4.6H2.1z"
            />
        </g>
        <g id="black_x5F_lines">
            <polygon
                className="st0"
                fill="#221F1F"
                points="44.9,74.8 25.7,43.7 28,39.4 49.2,74.8 	"
            />
            <polygon
                className="st0"
                fill="#221F1F"
                points="25.9,74.7 28.4,79.3 66,79.1 68.7,74.7 	"
            />
            <polygon
                className="st0"
                fill="#221F1F"
                points="55.9,95.8 60.6,95.9 80.7,62.6 78.3,58.8 	"
            />
            <polygon
                className="st0"
                fill="#221F1F"
                points="90.3,78.9 68.2,43.2 73.2,43.2 92.4,75.1 	"
            />
            <polygon
                className="st0"
                fill="#221F1F"
                points="90.5,39.4 92.6,43.4 49,43.4 51.3,39.4 	"
            />
            <polygon
                className="st0"
                fill="#221F1F"
                points="57.8,22.2 62.4,22.3 39.3,59.3 37.2,55.7 	"
            />
        </g>
    </svg>
);
