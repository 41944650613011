// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const CentralRGB = () => (
    <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 85.199999 96.199997"
        width="45.199997"
        height="96.199997"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/2000/svg"
    >
        <defs id="defs41" />
        <path
            className="st0"
            fill="#1E1F22"
            d="M 85.2,17.5 C 76.1,6.4 62.5,0 48.1,0 21.6,0 0,21.6 0,48.1 0,74.6 21.6,96.2 48.1,96.2 62.9,96.2 75.8,90 83.3,79.3 l -1.4,-1 C 74.7,88.6 62.3,94.5 48,94.5 22.4,94.5 1.6,73.7 1.6,48.1 c 0,-0.3 0,-0.6 0,-0.9 h 4.9 c 0.1,23.6 18.2,43.3 41.8,43.3 12.9,0 22.6,-6.1 30.6,-14.9 L 65.6,63.9 c 0,0 0,0 0,0 l -1.3,-1.1 c -4.1,4.6 -10,7.2 -16.2,7.2 -12,0 -21.8,-9.8 -21.8,-21.8 0,-12 9.8,-21.8 21.8,-21.8 6.4,0 12.4,2.8 16.6,7.6 L 66,32.9 c -4.5,-5.2 -11,-8.2 -17.9,-8.2 -12.6,0 -22.9,10 -23.5,22.5 h -5 C 19.7,33 31.3,19.4 47.8,19.4 c 9.3,0 16.9,4.1 21.8,10.1 z"
            id="path4"
        />
        <path
            className="st0"
            fill="#1E1F22"
            d="M 85.2,17.5 C 76.1,6.4 62.5,0 48.1,0 21.6,0 0,21.6 0,48.1 0,74.6 21.6,96.2 48.1,96.2 62.9,96.2 75.8,90 83.3,79.3 l -1.4,-1 C 74.7,88.6 62.3,94.5 48,94.5 22.4,94.5 1.6,73.7 1.6,48.1 c 0,-0.3 0,-0.6 0,-0.9 h 4.9 c 0.1,23.6 18.2,43.3 41.8,43.3 12.9,0 22.6,-6.1 30.6,-14.9 L 65.6,63.9 c 0,0 0,0 0,0 l -1.3,-1.1 c -4.1,4.6 -10,7.2 -16.2,7.2 -12,0 -21.8,-9.8 -21.8,-21.8 0,-12 9.8,-21.8 21.8,-21.8 6.4,0 12.4,2.8 16.6,7.6 L 66,32.9 c -4.5,-5.2 -11,-8.2 -17.9,-8.2 -12.6,0 -22.9,10 -23.5,22.5 h -5 C 19.7,33 31.3,19.4 47.8,19.4 c 9.3,0 16.9,4.1 21.8,10.1 z"
            id="path20"
        />
    </svg>
);
