// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const OpenGroundRGBLogo = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 81.2 81.2"
        enableBackground="0 0 81.2 81.2"
        xmlSpace="preserve"
        style={{ borderRadius: 4 }}
    >
        <g id="Layer_2_1_">
            <g>
                <g>
                    <linearGradient
                        id="SVGID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1="-636.9877"
                        y1="292.8319"
                        x2="-635.7342"
                        y2="292.8319"
                        gradientTransform="matrix(60.6199 60.7529 -60.7529 60.6199 56407.1992 20950)"
                    >
                        <stop offset="0" stopColor="#1067B2" />
                        <stop offset="0.2178" stopColor="#0762AC" />
                        <stop offset="0.5306" stopColor="#015499" />
                        <stop offset="0.8993" stopColor="#1E4078" />
                        <stop offset="0.9947" stopColor="#1F3A6E" />
                        <stop offset="1" stopColor="#1F3A6E" />
                    </linearGradient>
                    <polygon
                        className="st0"
                        clipPath="url(#SVGID_3_)"
                        fill="url(#SVGID_3_)"
                        points="40.7,-40.5 121.7,40.7 40.5,121.7 -40.5,40.5"
                    />
                </g>
            </g>
        </g>
        <g id="Layer_4">
            <path
                className="st1"
                fill="#1F3A6E"
                d="M69.9,30.5c-3.2,0-6.5,0.9-9.2,2.5c-3.2,1.9-6.8,3.6-10.7,4.9l-5,1.5c-2.2,0.5-4.4,0.9-6.6,1c-2,0.1-4,0-6,0
                c-3.1-0.1-6.1-0.3-9.2-0.2c-2.5,0.1-5.1,0.4-7.6,0.8c-1.4,0.2-2.9,0.5-4.3,0.9v5.5c0-0.1,2-0.7,2.2-0.8c1-0.3,2-0.7,2.9-1
                c2.3-0.8,4.6-1.5,7-2.2c2.8-0.6,5.5-1.2,8.3-1.7c3.8-0.8,8-0.7,11.9,0.1l3.5,0.8c0.5,0.2,1.1,0.3,1.6,0.5s1.1,0.4,1.6,0.6
                c1.1,0.4,2.2,0.9,3.1,1.4c2.1,1.1,4.5,1.7,6.9,1.7c0.9,0,1.8-0.1,2.7-0.2c3.4-0.6,6.5-2.2,9.2-4.8v-11
                C71.4,30.6,70.6,30.5,69.9,30.5"
            />
            <path
                className="st1"
                fill="#1F3A6E"
                d="M63.5,49.8c-1.1,0.2-2.1,0.3-3.2,0.3c-2.8,0-5.7-0.7-8.2-2c-0.9-0.5-1.8-0.9-2.8-1.3c-1.4-0.5-2.8-1-4.3-1.3
                l-3.4-0.6c-1.6-0.2-3.2-0.3-4.8-0.3c-4.6,0-9,0.9-13.3,2.3c-5.1,1.5-10,3.2-12.1,4V58c1.5-0.1,3-0.4,4.5-0.7
                c1.3-0.3,2.8-0.4,4.5-0.4c4,0,7.9,0.5,11.8,1.4l3.5,0.9c1.2,0.3,2.3,0.6,3.4,0.8c3.6,1,7.4,1.4,11.2,1.4c1.4,0,2.7-0.1,4.1-0.2
                c7.7-0.7,12.5-2.2,15.2-3.3c1.1-0.4,1.9-0.9,2.5-1.2V46C69.5,47.9,66.6,49.2,63.5,49.8"
            />
            <path
                className="st1"
                fill="#1F3A6E"
                d="M54.6,64.2c-1.4,0.1-2.9,0.2-4.4,0.2c-2.7,0-5.5-0.2-8.1-0.7c-2.9-0.5-5.8-1.3-8.7-2l-3.8-0.8
                c-3.1-0.6-6.1-0.9-9.2-0.9c-1.5,0-2.8,0.1-3.8,0.3c-1.7,0.4-3.5,0.6-5.2,0.8v7.1v3.5c3.9,2.3,11.1,4.3,15.5,5.2
                c5.8,1.2,11.8,1,17.6-0.3c0.5-0.1,1-0.2,1.5-0.4c5.3-1.4,12.8-5,18.7-5c1.7,0,3.2,0.1,4.5,0.4c1,0.2,2,0.4,3,0.5V60
                C69,61.5,63.6,63.3,54.6,64.2"
            />
            <path
                className="st1"
                fill="#1F3A6E"
                d="M39.9,12.7c-0.5,0-0.9,0-1.4,0.1c-1.6-3.9-5.5-6.6-9.9-6.6c-5.6,0-10.3,4.3-10.7,9.9c-4,0.5-7.2,4-7.2,8.2
                c0,4.6,3.7,8.3,8.3,8.3h1.9h3.9h3h3.8l0,0h6.1h1.7l0,0c0.2,0,0.3,0,0.5,0c5.5,0,9.9-4.4,9.9-9.9S45.4,12.7,39.9,12.7"
            />
        </g>
        <g id="Layer_3">
            <path
                className="st2"
                fill="#FFFFFF"
                d="M68.8,28.8c-3.2,0-6.5,0.9-9.2,2.5c-3.2,1.9-6.8,3.6-10.7,4.9l-5,1.5c-2.2,0.5-4.4,0.9-6.6,1c-2,0.1-4,0-6,0
                c-3.1-0.1-6.1-0.3-9.2-0.2c-2.5,0.1-5.1,0.4-7.6,0.8c-1.4,0.2-2.9,0.5-4.3,0.9v5.5c0-0.1,2-0.7,2.2-0.8c1-0.3,2-0.7,2.9-1
                c2.3-0.8,4.6-1.5,7-2.2c2.8-0.6,5.5-1.2,8.3-1.7c3.8-0.8,8-0.7,11.9,0.1l3.5,0.8c0.5,0.2,1.1,0.3,1.6,0.5s1.1,0.4,1.6,0.6
                c1.1,0.4,2.2,0.9,3.1,1.4c2.1,1.1,4.5,1.7,6.9,1.7c0.9,0,1.8-0.1,2.7-0.2c3.4-0.6,6.5-2.2,9.2-4.8v-11
                C70.2,28.9,69.5,28.8,68.8,28.8"
            />
            <path
                className="st2"
                fill="#FFFFFF"
                d="M62.3,48.1c-1.1,0.2-2.1,0.3-3.2,0.3c-2.8,0-5.7-0.7-8.2-2c-0.9-0.5-1.8-0.9-2.8-1.3c-1.4-0.5-2.8-1-4.3-1.3
                l-3.4-0.6c-1.6-0.2-3.2-0.3-4.8-0.3c-4.6,0-9,0.9-13.3,2.3c-5.1,1.5-10,3.2-12.1,4v7.1c1.5-0.1,3-0.4,4.5-0.7
                c1.3-0.3,2.8-0.4,4.5-0.4c4,0,7.9,0.5,11.8,1.4l3.5,0.8c1.2,0.3,2.3,0.6,3.4,0.8c3.6,1,7.4,1.4,11.2,1.4c1.4,0,2.7-0.1,4.1-0.2
                c7.7-0.7,12.5-2.2,15.2-3.3c1.1-0.4,1.9-0.9,2.5-1.2V44.3C68.4,46.2,65.5,47.5,62.3,48.1"
            />
            <path
                className="st2"
                fill="#FFFFFF"
                d="M53.5,62.4c-1.4,0.1-2.9,0.2-4.4,0.2c-2.7,0-5.5-0.2-8.1-0.7c-2.9-0.5-5.8-1.3-8.7-2l-3.8-0.8
                c-3.1-0.6-6.1-0.9-9.2-0.9c-1.5,0-2.8,0.1-3.8,0.3c-1.7,0.4-3.5,0.6-5.2,0.8v7.3v3.5c3.9,2.3,11.1,4.3,15.5,5.2
                c5.8,1.2,11.8,1,17.6-0.3c0.5-0.1,1-0.2,1.5-0.4c5.3-1.4,12.8-5,18.7-5c1.7,0,3.2,0.1,4.5,0.4c1,0.2,2,0.4,3,0.5V58.3
                C67.9,59.8,62.4,61.6,53.5,62.4"
            />
            <path
                className="st2"
                fill="#FFFFFF"
                d="M39.3,11c-0.5,0-0.9,0-1.4,0.1c-1.6-3.9-5.5-6.6-9.9-6.6c-5.6,0-10.3,4.3-10.7,9.9c-4,0.5-7.2,4-7.2,8.2
                c0,4.6,3.7,8.3,8.3,8.3h1.9h3.9h3H31l0,0h6.1h1.7l0,0c0.2,0,0.3,0,0.5,0c5.5,0,9.9-4.4,9.9-9.9C49.3,15.5,44.8,11,39.3,11"
            />
        </g>
    </svg>
);
