import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        rootLegacy: {
            width: theme.spacing(8.75), // EdgeHTML 18 lacks support for 'min-content', which is about 70px,
            float: 'right', // fix for weird stuff happening with the popper affecting the width on open specifically on Safari.
            maxWidth: theme.spacing(37),
            height: '100%',
            paddingLeft: '1px',
            backgroundColor: theme.palette.grey[50],
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[100],
            },
        },
        rootDarkLegacy: {
            backgroundColor: theme.palette.grey[900],
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[800],
            },
            '&:active': {
                backgroundColor: theme.palette.grey[800],
            },
            '&:focus': {
                backgroundColor: theme.palette.grey[800],
            },
        },
        // Before WDS2 we had a <Grid> that was clickable. These styles help an IconButton look more like that did.
        buttonLegacy: {
            padding: 0,
            width: '100%',
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        iconLegacy: {
            color: '#1D1F23',
            height: theme.spacing(8),
        },
        iconDarkLegacy: {
            color: theme.palette.grey[300],
        },
        openLegacy: {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            boxShadow: '0 1px 6px 0 rgba(29, 31, 35, 0.13)',
        },
        openDarkLegacy: {
            color: 'white',
            backgroundColor: theme.palette.grey[900],
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            boxShadow: '0 1px 6px 0 rgba(29, 31, 35, 0.13)',
            '&:hover': {
                backgroundColor: theme.palette.grey[800],
            },
        },
        contentContainerLegacy: {
            borderRadius: 0,
        },
    }) as const;

export const useStyles = makeStyles()(styles);
