// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { HTMLAttributes } from 'react';

export function EvoBadge(props: HTMLAttributes<SVGElement>) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 283.5 283.5"
            xmlSpace="preserve"
            {...props}
        >
            <g>
                <path
                    fill="none"
                    d="M53.8,36.7v201.5h175.9v-63.6c-0.4,7.5-3.3,17.2-14.4,23.8l-58.7,35.2c-2.6,1.3-4.9,2.2-7.5,3.2
                        c-4.4,2-10,1.4-14.7-0.2c-2.4-0.6-4.9-1.7-7.5-3l-58.7-35.2c-11.1-6.6-14-16.3-14.4-23.8l0.4-78.9c0.3-8.4,6.7-15.6,12.2-18.7
                        L130,40.5c8.8-4.3,13.3-5.5,22.1-1c8.2,4.3,23,12.9,34.4,19.3l27.1,15.9l3.5,2.2c5.5,3.1,11.9,10.3,12.2,18.7l0.4,78.7V36.7H53.8z"
                />
                <path
                    fill="#FFFFFF"
                    d="M217.1,76.9l-3.5-2.2l-27.1-15.9c-11.5-6.4-26.2-15-34.4-19.3c-8.8-4.6-13.2-3.3-22.1,1L66.3,76.9
                        C60.8,80,54.4,87.2,54.1,95.7l-0.4,78.9c0.4,7.5,3.3,17.2,14.4,23.8l58.7,35.2c2.6,1.3,5.1,2.4,7.5,3c4.7,1.7,10.3,2.3,14.7,0.2
                        c2.5-1,4.9-2,7.5-3.2l58.7-35.2c11.1-6.6,14-16.3,14.4-23.8v-0.2l-0.4-78.7C229,87.2,222.6,80,217.1,76.9z M107.4,128.9l1.2-1.2
                        h33.3l1.2,1.2v17l-1.2,1.2h-33.3l-1.2-1.2V128.9z M176.1,184.4l-1.2,1.2h-66.4l-1.2-1.2v-17l1.2-1.2H175l1.2,1.2V184.4z
                         M176.1,107.5l-1.2,1.2h-66.4l-1.2-1.2v-17l1.2-1.2H175l1.2,1.2V107.5z"
                />
            </g>
        </svg>
    );
}

export default EvoBadge;
