// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const VariphyRGB = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        enableBackground="new 0 0 1000 1000"
    >
        <g>
            <path
                fill="#00B072"
                strokeWidth="4.1105"
                strokeMiterlimit={10}
                className="st0"
                d="M500.3,987c269.2,0,487.5-218.3,487.5-487.5S769.6,12,500.3,12S12.8,230.3,12.8,499.5S231.1,987,500.3,987"
            />
            <path
                fill="#FFFFFF"
                className="st1"
                d="M500.3,543.9L332.2,258.6l4.1-2.9c49.3-32.5,105.2-49.3,164-49.3c7.8,0,16.9,0,24.7,0.8h2.9l0.8,2.9
		c4.9,14.8,18.5,24.7,33.3,24.7c19.7,0,35.3-15.6,35.3-35.3s-15.6-35.3-35.3-35.3c-9.9,0-18.5,4.1-25.5,10.7l-2.1,2.1h-2.1
		c-12.7-0.8-22.6-2.1-32.5-2.1c-71.9,0-140.6,22.6-198.5,65.8c-5.8,4.9-7.8,13.6-4.1,19.7l171,289.8c5.8,10.7,18.5,17.7,31.7,17.7
		c12.7,0,25.5-7,31.7-18.5L687,277.5l2.9-4.9l4.1,4.1c70.7,60,108.1,147.6,104,239.6c-0.8,25.5-5.8,50.1-12.7,74.8l-0.8,2.9h-2.9
		c-18.5,2.1-32.5,16.9-32.5,35.3c0,19.7,15.6,35.3,35.3,35.3c19.7,0,35.3-15.6,35.3-35.3c0-7-2.1-13.6-5.8-19.7l-0.8-2.1l0.8-2.1
		c9-28.4,14.8-58,15.6-87.6c4.9-110.2-45.2-214.2-134.8-279.9c-2.9-2.9-7.8-4.1-11.9-2.9c-4.1,0.8-7.8,2.9-9.9,7L500.3,543.9z"
            />
            <path
                fill="#FFFFFF"
                className="st1"
                d="M275.1,745.3c67,62.9,154.1,94.5,246.6,88.4c60-4.1,118-24.7,168.1-58.8l2.1-2.1l2.9,0.8
		c4.9,2.1,9.9,2.9,14.8,2.9c19.7,0,35.3-15.6,35.3-35.3s-15.6-35.3-35.3-35.3s-35.3,15.6-35.3,35.3v5.8l-2.1,2.1
		c-45.2,32.5-98.2,51-152.5,53.8c-83.4,4.9-163.2-23.4-223.2-80.6c-60.8-57.1-94.5-134.8-94.5-217c0-46,10.7-93.3,31.7-134.8
		l4.1-7.8L444,707.5C456,728,476.5,740,499.9,740s44.4-11.9,55.1-31.7l152.5-249.5h2.9c18.5-0.8,33.3-16.9,33.3-35.3
		c0-19.7-15.6-35.3-35.3-35.3c-19.7,0-35.3,15.6-35.3,35.3c0,7,2.1,12.7,5.8,18.5l2.1,2.9L528.3,691.5c-5.8,9.9-16.9,16.9-28.4,16.9
		c-11.5,0-22.6-5.8-28.4-16.9L251.7,324.4c-2.9-4.9-7.8-7.8-12.7-7.8h-0.8c-4.9,0-9.9,2.9-12.7,7c-35.3,53.8-53.8,117.1-53.8,181.7
		C171.1,595.7,209.3,683.2,275.1,745.3"
            />
        </g>
    </svg>
);
