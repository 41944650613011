// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const MXDepositIcon = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 62 73.6"
        enableBackground="0 0 62 73.6;"
        xmlSpace="preserve"
    >
        <g id="Symbol">
            <polygon
                className="st0"
                fill="#B0DBE0"
                points="32.7,39.8 62,22.8 62,56.7 62,56.7 62,56.7 	"
            />
            <polygon className="st0" fill="#B0DBE0" points="62,56.7 32.7,39.8 62,22.8 	" />
            <polygon
                className="st1"
                fill="#84C9D2"
                points="32.7,73.6 32.7,73.6 32.7,39.8 62,56.7 	"
            />
            <polygon className="st1" fill="#84C9D2" points="32.7,39.8 62,56.7 32.7,73.6 	" />
            <polygon
                className="st2"
                fill="#D8EDEF"
                points="32.7,39.8 32.7,39.8 32.7,5.9 62,22.8 	"
            />
            <polygon className="st2" fill="#D8EDEF" points="32.7,5.9 62,22.8 32.7,39.8 	" />
            <polygon
                className="st3"
                fill="#51B8C3"
                points="3.4,56.7 32.7,39.7 32.7,73.6 32.7,73.6 32.7,73.6 	"
            />
            <polygon className="st3" fill="#51B8C3" points="32.7,73.6 3.4,56.7 32.7,39.8 	" />
            <polygon
                className="st4"
                fill="#00A7B7"
                points="3.4,56.7 3.4,56.7 3.4,22.8 32.7,39.7 	"
            />
            <polygon className="st4" fill="#00A7B7" points="3.4,22.8 32.7,39.8 3.4,56.7 	" />
            <polygon
                className="st5"
                fill="#F36C3F"
                points="0,16.9 29.3,0 29.3,33.8 29.3,33.9 29.3,33.9 	"
            />
            <polygon className="st5" fill="#F36C3F" points="29.3,33.8 0,16.9 29.3,0 	" />
        </g>
    </svg>
);
