// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from 'react';

export const DriverRGB = () => {
    const xmlnsAttributes = {
        'xmlns:x': '&ns_extend',
        'xmlns:i': '&ns_ai',
        'xmlns:graph': '&ns_graphs',
    };
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            {...xmlnsAttributes}
            enableBackground="new 0 0 1000 1000"
        >
            <path
                fill="#0091FF"
                className="st0"
                d="M507.5,12.3L507.5,12.3v-0.2c0-0.1-27.3-0.2-60.9-0.2H80.9C47.3,12,20,39.3,20,72.9v852.5
	c0,33.7,27.3,60.9,60.9,60.9h389.8c7.7,0.4,15.4,0.6,23.2,0.6c269.2,0,487.5-218.3,487.5-487.5C981.4,234.8,770.4,19.5,507.5,12.3"
            />
            <path
                fill="#FFFFFF"
                className="st1"
                d="M368.2,629.6v-242h121.1c33,0,63.2,13.7,85.2,35.7c22,22,35.7,52.2,35.7,85.4c0,33-13.7,63.2-35.7,85.2
	c-22,22-52.2,35.7-85.2,35.7H368.2z M489.4,850.6c93.9,0,179.5-38.5,241.3-100.5c62-61.8,100.5-147.5,100.5-241.3
	c0-94.1-38.5-179.5-100.5-241.6c-61.8-61.8-147.5-100.3-241.3-100.3H332.5v35.7V324v27.9v17.9v259.9h-56.8V472.3v-3.2v-4.6v-4.8v-5
	v-5.3v-5.7v-6.2v-6.2v-6.9v-6.9V184.2c0-9.9-8-17.9-17.9-17.9c-9.8,0-17.9,8-17.9,17.9v233.4v6.9v6.9v6.2v6.2v5.7v5.3v5v4.8v4.6v3.2
	v157.3h-56.8V472.3v-3.2v-4.6v-4.8v-5v-5.3v-5.7v-6.2v-6.2v-6.9v-91v-25c0-9.8-8-17.9-17.9-17.9c-9.8,0-17.9,8-17.9,17.9v25v138.9
	v193h35.7h37.3h112h17.9h139c42.8,0,82-17.6,110.4-46.3c28.6-28.4,46.3-67.5,46.3-110.4c0-43-17.6-82.2-46.3-110.6
	c-28.4-28.4-67.5-46.3-110.4-46.3H368.2v-56.8h121.1c58.6,0,112,24,150.7,62.7c38.7,38.7,63,92.3,63,150.9c0,58.6-24.3,112-63,150.7
	c-38.7,38.7-92,63-150.7,63h-37.1H452H450h-1.8h-1.8h-1.4h-1.4h-0.9h-0.9h-0.7h-0.7h-0.5h-0.2h-0.2h0.2h0.2h0.2h0.5h0.5h0.5h0.5h0.5
	h0.5h0.5h0.5h0.2h0.5h0.2h0.2h0.2h-0.2h-0.2h-0.5h-0.5h-0.9h-0.7h-1.1h-1.4h-1.4h-1.8h-1.8h-2.1h-2.5h-2.5h-3h-3.2h-3.7H414h-4.1
	h-4.6h-4.8h-5.3H159.7c-9.8,0-17.9,8-17.9,17.6c0,9.9,8,17.9,17.9,17.9h235.5h5.3h4.8h4.6h4.1h3.7h3.7h3.2h3h2.5h2.5h2.1h1.8h1.8
	h1.4h1.4h1.1h0.7h0.9h0.5h0.5h0.2h0.2h-0.2h-0.2h-0.2H444h-0.2h-0.5h-0.5h-0.5H442h-0.5h-0.5h-0.5h-0.5h-0.2h-0.2h-0.2h0.2h0.2h0.5
	h0.7h0.7h0.9h0.9h1.4h1.4h1.8h1.8h2.1h0.2h37.1c68.5,0,130.7-27.9,175.8-73.3c45.3-45.1,73.3-107.4,73.3-175.8
	c0-68.5-27.9-130.7-73.3-176.1c-45.1-45.1-107.4-73.3-175.8-73.3H368.2v-56.8h121.1c84,0,160.7,34.3,216.1,89.8
	c55.4,55.6,90,132.1,90,216.4c0,84-34.6,160.7-90,216.1c-55.4,55.4-132.1,90-216.1,90h-37.1H452H450h-1.8h-1.8h-1.4h-1.4h-0.9h-0.9
	h-0.7h-0.7h-0.5h-0.2h-0.2h0.2h0.2h0.2h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.5h0.2h0.5h0.2h0.2h0.2h-0.2h-0.2h-0.5h-0.5h-0.9h-0.7h-1.1
	h-1.4h-1.4h-1.8h-1.8h-2.1h-2.5h-2.5h-3h-3.2h-3.7H414h-4.1h-4.6h-4.8h-5.3h-22.9c-9.8,0-17.9,8-17.9,17.9s8,17.9,17.9,17.9h22.9
	H452H489.4z"
            />
        </svg>
    );
};
