import { common } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { getSpacing } from '@local/web-design-system/dist/styles/getSpacing';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ variant: string }>()((theme: Theme, { variant }) => ({
    root: {
        boxSizing: 'border-box',
        maxWidth: theme.spacing(40),
        minHeight: theme.spacing(25),
        position: 'relative',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    rootLegacy: {
        maxWidth: theme.spacing(56.25),
        minHeight: theme.spacing(25),
        position: 'relative',
        borderRadius: 0,
    },
    titleLegacy: {
        ...fonts.widget2Heading,
        color: 'white',
    },
    titleDarkLegacy: {
        ...fonts.widget2Heading,
        backgroundColor: theme.palette.grey[800],
    },
    headerLegacy: {
        backgroundColor: theme.palette.grey[700],
        height: theme.spacing(6),
    },
    headerDarkLegacy: {
        backgroundColor: `${theme.palette.grey[800]} !important`,
    },
    headerTitleContainerLegacy: {
        flexGrow: 2,
        paddingLeft: theme.spacing(3),
    },
    toolbar: {
        backgroundColor: theme.palette.grey[50],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    iconText: {
        paddingLeft: theme.spacing(2),
        textTransform: 'none',
    },
    noTextTransform: {
        textTransform: 'none',
    },
    content: {
        maxHeight: `calc(100vh - ${getSpacing(theme, 37.75)}px)`,
        overflowX: 'hidden', // Suppress scrollbar due to padding
        padding: `${getSpacing(theme, 2.75)} 0`,
        ...common(theme).thinScrollBar,
    },
    button: {},
    justifyLeft: {
        justifyContent: 'left',
    },
    tableWrapper: {
        tableLayout: 'fixed', // Define col widths to avoid Y overflow
        maxWidth: theme.spacing(54), // Confine table to tray maxWidth-padding
    },
    itemImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.spacing(6),
        width: theme.spacing(6),

        '& > svg': {
            color: 'currentcolor',
            height: theme.spacing(4),
            width: theme.spacing(4),
        },
    },
    itemImageLegacy: {
        '& > svg': {
            height: theme.spacing(5.25),
            width: theme.spacing(5.25),
            marginTop: theme.spacing(0.5),
        },
    },
    itemTitle: {
        fontSize: '18px',
        letterSpacing: '0.09px',
        lineHeight: '22px',
    },
    itemDesc: {
        fontSize: '14px',
        letterSpacing: '0.11px',
        lineHeight: '21px',
    },
    itemLink: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'var(--mui-palette-text-primary)',
        padding: theme.spacing(1),
        borderRadius: 'var(--mui-shape-borderRadius)',

        '&:hover': {
            background: 'var(--mui-palette-secondary-_states-hover)',
        },

        '&:focus': {
            background: 'var(--mui-palette-secondary-_states-focus)',
        },
    },
    itemLinkLegacy: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        color: variant === 'dark' ? 'white' : 'black',
        fontWeight: fonts.weights.medium,
        textDecoration: 'none',
        fontSize: '12px',
        letterSpacing: '0.11px',
        lineHeight: '21px',
    },
    item: {
        padding: theme.spacing(0, 1),
    },
    appMenuContainer: {
        margin: 'auto',
        gap: theme.spacing(1),
    },
    appMenuContainerLegacy: {
        margin: 'auto',
        gap: 12,
        padding: theme.spacing(1.25),
        width: theme.spacing(41.25),
    },
    appItem: {
        width: theme.spacing(11.32775),
    },
    appItemLegacy: {
        justifyContent: 'center',
        height: theme.spacing(9.625),
        width: theme.spacing(11.875),
        borderRadius: 4,
        '&:hover': {
            border: `1px solid ${theme.palette.grey[300]}`,
        },
    },
}));
